import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { CollapseProps, Collapse, Drawer, Tabs, TabsProps } from "antd";
import {
  HiOutlineBanknotes,
  HiOutlineUsers,
  HiOutlineXMark,
  HiShieldCheck,
} from "react-icons/hi2";
import influencerPlaceholder from "../../assets/imgs//influencer_placeholder.svg";
import { useEffect, useState } from "react";
import InfluencerDetailsPlatformItem from "../InfluencerDetailsPlatformItem";
import Service from "../InfluencerServicesCard/Service/Index";
import { formatNumber } from "../../Utils/helpers";
import { IoIosMale, IoIosFemale } from "react-icons/io";
import CampaignDetailsSkeleton from "../campaign-details-skeleton/campaign-details-skeleton";
import InfluencerBasicInfo from "./InfluencerBasicInfo";
import InfluencerBadges from "./InfluencerBadges";
import InfluencerRelations from "./InfluencerRelations";
import InfluencerExclusiveBrands from "./InfluencerExclusiveBrands";
import MyCollapse from "../Collapse";
import InfluencerTags from "./InfluencerTags";

const InfluencerDetails = (props: any) => {
  const {
    t,
    i18n: { dir, language },
  } = useTranslation();
  const [socialItems, setSocialItems] = useState<CollapseProps["items"]>([]);
  const [tabsItems, setTabsItems] = useState<TabsProps["items"]>([]);
  const [isScrolling, setIsScrolling] = useState(false);

  const [showScroll, setShowScroll] = useState(false);

  const handleScroll = (e: any) => {
    setShowScroll(true);

    var isScrolled = e.target.scrollTop > 0;

    if (isScrolled) {
      document.querySelector(".ant-drawer-header")!.classList.add("showShadow");
    } else {
      document
        .querySelector(".ant-drawer-header")!
        .classList.remove("showShadow");
    }
  };
  const handleSocialPlatformsData = async () => {
    const tempItems: CollapseProps["items"] = [];
    // Use Promise.all to map promises and await them
    await Promise.all(
      props.influencerDetails.influencerSocialPlatforms?.map(
        async (socialItem: any, index: any) => {
          if (socialItem.isActive) {
            console.log("socialItem: " + socialItem);
            if (socialItem.services?.length > 0) {
              const services = await Promise.all(
                socialItem.services.map(async (service: any, index: any) => (
                  <Service
                    isDetailsBox={true}
                    data={service}
                    platformKey={socialItem.key}
                    influencerId={props.influencerDetails.id}
                    key={service.advertisingProductId}
                    serviceKey={service.key}
                    name={service.name}
                    state="view"
                    currencyKey={service.currencyKey}
                    price={service.basePrice}
                    basePrice={service.basePrice}
                  />
                ))
              );

              tempItems.push({
                key: index,
                label: (
                  <InfluencerDetailsPlatformItem
                    platformImg={socialItem.logoUrl}
                    platformName={socialItem.name}
                    platfromLink={socialItem.socialPlatformUrl}
                    followersCount={socialItem.followersCount}
                    isVerified={socialItem.isVerified}
                    platformKey={socialItem.key}
                    isActive={socialItem.isActive}
                  />
                ),
                children: (
                  <>
                    <div className={styles.services_container}>
                      {socialItem.services.map((service: any, index: number) => (
                        <Service
                          isDetailsBox={true}
                          data={service}
                          platformKey={socialItem.key}
                          influencerId={props.influencerDetails.id}
                          key={service.advertisingProductId}
                          serviceKey={service.key}
                          name={service.name}
                          state="view"
                          currencyKey={service.currencyKey}
                          price={service.basePrice}
                          basePrice={service.basePrice}
                        />
                      ))}
                    </div>
                    {socialItem.audienceSegmentation &&
                      !Object.values(socialItem.audienceSegmentation).every(
                        (a) => a === null || (Array.isArray(a) && a.length === 0)
                      ) && (
                        <div className={styles.segments_wrapper + " " + "_lr-hide"}
                          data-private>
                          <h6 className={styles.segments_title}>
                            {t("top_segments")}
                          </h6>
                          <div className={styles.segments_container}>
                            {socialItem.audienceSegmentation?.countries?.length >
                              0 && (socialItem.audienceSegmentation?.countries[0]?.percentage > 0 || socialItem.audienceSegmentation?.countries[1]?.percentage > 0) && (
                                <div className={styles.segment_item}>
                                  <span className={styles.segment_title}>
                                    {t("countries")}
                                  </span>
                                  <div className={styles.segment_content}>
                                    {socialItem.audienceSegmentation?.countries.map(
                                      (c: any) => (
                                        c.percentage > 0 ?
                                          <div
                                            key={c.key}
                                            className={styles.segment_content_item}
                                          >
                                            {/* <Flag
                                            height="24"
                                            width="24"
                                            className={
                                              styles.segment_content_item_flag
                                            }
                                            code={c.key.toLowerCase()}
                                          /> */}
                                            <span
                                              className={`${styles.segment_content_item_flag
                                                } fi fis fi-${c.isoTwoCode.toLowerCase()}`}
                                            ></span>
                                            <span>{Math.round(c.percentage)}%</span>
                                          </div>
                                          : "")
                                    )}
                                  </div>
                                </div>
                              )}
                            {socialItem.audienceSegmentation?.genders?.length >
                              0 && (socialItem.audienceSegmentation?.genders[0]?.percentage > 0 || socialItem.audienceSegmentation?.genders[1]?.percentage > 0) && (
                                <div className={styles.segment_item}>
                                  <span className={styles.segment_title}>
                                    {t("genders")}
                                  </span>
                                  <div className={styles.segment_content}>
                                    {socialItem.audienceSegmentation.genders.map(
                                      (g: any) => (g.percentage > 0 ? (
                                        <div
                                          key={g.key}
                                          className={styles.segment_content_item}
                                        >
                                          {g.key === "Male" ? (
                                            <IoIosMale
                                              className={styles.maleIcon}
                                            />
                                          ) : (
                                            <IoIosFemale
                                              className={styles.femaleIcon}
                                            />
                                          )}
                                          <span>{Math.round(g.percentage)}%</span>
                                        </div>) : ""
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                            {socialItem.audienceSegmentation?.ageRanges && socialItem.audienceSegmentation?.ageRanges
                              .percentage > 0 ? (
                              <div className={styles.segment_item}>
                                <span className={styles.segment_title}>
                                  {t("agegroups")}
                                </span>
                                <div className={styles.segment_content}>
                                  <div className={styles.segment_content_item}>
                                    <HiOutlineUsers
                                      className={styles.age_groups_icon}
                                    />
                                    {socialItem.audienceSegmentation?.ageRanges
                                      .from &&
                                      socialItem.audienceSegmentation?.ageRanges
                                        .to ? (
                                      <label>
                                        {
                                          socialItem.audienceSegmentation
                                            ?.ageRanges.from
                                        }{" "}
                                        -{" "}
                                        {
                                          socialItem.audienceSegmentation
                                            ?.ageRanges.to
                                        }
                                      </label>
                                    ) : (
                                      <label style={language == 'ar' ? { direction: "ltr" } : { direction: "rtl" }}>
                                        {
                                          socialItem.audienceSegmentation
                                            ?.ageRanges.from
                                        }
                                      </label>
                                    )}
                                    <span className={styles.moreSpace}>
                                      {Math.round(
                                        socialItem.audienceSegmentation?.ageRanges
                                          .percentage
                                      )}
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : ""}
                            {socialItem.audienceSegmentation?.otherSegments
                              ?.length > 0 && (
                                <div
                                  className={
                                    styles.segment_item + " " + styles.otherSegments
                                  }
                                >
                                  <span className={styles.segment_title}>
                                    {t("other_segements")}
                                  </span>
                                  <div className={styles.segment_content}>
                                    {socialItem.audienceSegmentation?.otherSegments?.map(
                                      (s: any) => (
                                        <div key={s} className={styles.segment_tag}>
                                          {s}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      )}
                  </>
                ),
              });
            }
          }
        }
      )
    );
    setSocialItems(tempItems);
    return tempItems;
  };
  const influencerInfo = () => {
    let arr = [
      {
        label: <p>{t("basic_info")}</p>,
        children: <InfluencerBasicInfo details={props.influencerDetails} />,
      },
    ]
    if (props.influencerDetails?.metaData?.commitmentLevel || props.influencerDetails?.metaData?.allowInsightsSharing || props.influencerDetails?.metaData?.hasMawthok) {
      arr.push({
        label: <p>{t("badges")}</p>,
        children: <InfluencerBadges details={props.influencerDetails} />,
      })
    }
    if (props.influencerDetails?.relationships) {
      arr.push({
        label: <p>{t("relationships")}</p>,
        children: <InfluencerRelations details={props.influencerDetails} />,
      })
    }
    if (props.influencerDetails?.influencerTags && props.influencerDetails?.influencerTags.length) {
      arr.push({
        label: <p>{t("tags")}</p>,
        children: <InfluencerTags details={props.influencerDetails} />,
      })
    }
    // if (props.influencerDetails?.exclusiveBrands) {
    //   arr.push({
    //     label: <p>{t("exclusive_brands")}</p>,
    //     children: (
    //       <InfluencerExclusiveBrands details={props.influencerDetails} />
    //     ),
    //   },)
    // }
    return arr
  };
  const handleSocialPlatformTab = (res: any) => {
    let tempTabsItems: TabsProps["items"] = [];
    tempTabsItems[1] = {
      key: "2",
      label: t("platforms_services"),
      children: (
        <div className={styles.influencer_platforms}>
          {/* <h6 className="title">{t("platforms_services")}</h6> */}
          {/* <p className={styles.influencer_price_range + " " + "_lr-hide"}
            data-private>
            <HiOutlineBanknotes />
            {props.influencerDetails?.influencerSocialPlatforms?.maxBasePrice >
            0 ? (
              <span>
                {t("service_price_range")}
                {props.influencerDetails?.influencerSocialPlatforms
                  ?.minBasePrice &&
                  formatNumber(
                    props.influencerDetails?.influencerSocialPlatforms
                      .minBasePrice
                  )}{" "}
                ~
                {props.influencerDetails?.influencerSocialPlatforms
                  ?.maxBasePrice &&
                  formatNumber(
                    props.influencerDetails?.influencerSocialPlatforms
                      .maxBasePrice
                  )}
              </span>
            ) : (
              <span className={styles.noPrices}>
                {t("no_available_prices")}
              </span>
            )}
          </p> */}
          <div className={styles.services_wrapper}>
            <Collapse
              defaultActiveKey={Array.from({ length: res.length + 1 }, (_, i) =>
                i.toString()
              )}
              items={res}
              className="inf_details_accordion"
            />
          </div>
        </div>
      ),
    };
    tempTabsItems[0] = {
      key: "1",
      label: t("info"),
      children: (
        <Collapse
          defaultActiveKey={Array.from(
            { length: 10 },
            (_, i) => i.toString()
          )}
          items={influencerInfo()}
          className="inf_details_accordion inf_info"
        />
      ),
    };
    setTabsItems(tempTabsItems);
  };
  useEffect(() => {
    const fetchData = async () => {
      if (props.influencerDetails) {
        await handleSocialPlatformsData().then((res: any) => {
          handleSocialPlatformTab(res);
        });
      }
    };
    fetchData();
  }, [props.influencerDetails]);
  const handleImgError = (e: any) => {
    e.target.src = influencerPlaceholder;
  };
  const handleDrawerScroll = (e: any) => {
    const { scrollTop } = e.target;
    setIsScrolling(scrollTop > 0);
  };
  const onClose = () => {
    props.onClose();
  };

  return (
    <div className="drawer_wrapper">
      <Drawer
        title={null}
        destroyOnClose={true}
        placement={dir() === "ltr" ? "right" : "left"}
        closable={true}
        onClose={onClose}
        closeIcon={<HiOutlineXMark className="close-drawer" />}
        open={props.open}
        getContainer={props.container}
        className={`${isScrolling ? "scrolling-drawer" : ""}`}
        contentWrapperStyle={{ width: "36.75rem" }}
      >
        <div className="scrolling-drawer-content" onScroll={handleDrawerScroll}>
          <div
            className={`${styles.influencer_details} ${
              showScroll ? "showScroll" : ""
            } influencerDetails`}
            onScroll={handleScroll}
          >
            {props.loading ? (
              <CampaignDetailsSkeleton />
            ) : (
              <>
                <div className={styles.influencer_basicInfo + " " + "_lr-hide"}
                  data-private>
                  <div className={styles.influencer_img_container}>
                    <img
                      src={
                        props.influencerDetails?.avatarUrl ||
                        influencerPlaceholder
                      }
                      alt={props.influencerDetails?.name}
                      onError={handleImgError}
                    />
                    {/* <div className={styles.influencer_quality}>
            <HiStar />
            <span>{props.influencerDetails.influencerQualityKey.name}</span>
          </div> */}
                  </div>
                  <div className={styles.influencer_info}>
                    <h4 className={styles.influencer_name}>
                      <span>{props.influencerDetails?.name}</span>
                      {/* {props.influencerDetails?.isFeatured && <HiShieldCheck />} */}
                    </h4>
                    {/* <p className={styles.influencer_id}>
                      INF - {props.influencerDetails?.id}
                    </p> */}
                    {props.influencerDetails?.influencerNicheKeys && (
                      <p className={styles.influencer_niche}>
                        {props.influencerDetails?.influencerNicheKeys
                          .map((niche: any) => {
                            return niche.name;
                          })
                          .join(", ")}{" "}
                      </p>
                    )}

                    {/* {props.influencerDetails?.influencerBrandSafetyFlagKeys && (
                      <div className={styles.influencer_status_contaier}>
                        {props.influencerDetails?.influencerBrandSafetyFlagKeys?.map(
                          (brandSaftey: any, index: number) => (
                            <span
                              key={brandSaftey.key}
                              className={styles.influencer_status_item}
                            >
                              {brandSaftey.name}
                            </span>
                          )
                        )}
                      </div>
                    )} */}
                  </div>
                </div>
                <Tabs
                  defaultActiveKey="1"
                  items={tabsItems}
                  className="inf_tabs"
                />
              </>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default InfluencerDetails;
